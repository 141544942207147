























































section {
  main {
    width: 1200px;
    margin: 0 auto;
    text-align: center;

    > h2 {
      font-size: 36px;
      font-weight: normal;
      color: #333;
      padding: 50px 0;
    }

    > div {
      margin-bottom: 50px;

      > h4 {
        text-align: left;
        font-size: 26px;
        font-weight: normal;
        color: #333;
        margin-bottom: 10px;
      }
    }
  }
}
@media (max-width: 767px){
  section{
    >main{
      width: 100%;
      ::v-deep video {
        width: 100%;
        max-width: 1200px;
      }
    }
  }
}
